/** @format */

import ServiceUrls from '../../../services/service.urls';

// ** Auth Endpoints
const JwtConfig = {
	loginEndpoint: ServiceUrls.loginUrl,
	// registerEndpoint: '/jwt/register',
	// refreshEndpoint: '/jwt/refresh-token',
	// logoutEndpoint: '/jwt/logout',

	// ** This will be prefixed in authorization header with token
	// ? e.g. Authorization: Bearer <token>
	// tokenType: 'Bearer',

	// ** Value of this property will be used as key to store JWT token in storage
	storageTokenKeyName: 'ME-AUTHORIZATION',
};

export default JwtConfig;
