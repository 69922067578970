/** @format */

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      // state.userData = action.payload;
      // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName];
      // localStorage.setItem('userData', JSON.stringify(action.payload));
      // localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken);
    },
    handleUserUpdate: (state, action) => {
      state.userData = action.payload;
      localStorage.setItem("userData", JSON.stringify(action.payload));
    },
    handleLogout: (state) => {
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      window.localStorage.setItem("isLoggedIn", "false");
      window.location.href = "/login";
    },
  },
});

export const { handleLogin, handleLogout, handleUserUpdate } =
  authSlice.actions;

export default authSlice.reducer;
