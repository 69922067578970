/** @format */

class ServiceUrls {
	static basePath = `https://hr-api.marquee-equity.com/`;
	static authBasePath = `https://auth.marquee-equity.com/`;
	// static basePath = `http://localhost:3005/`;
	static loginUrl = `${ServiceUrls.basePath}api/v1/user/login`;
	static getSelf = `${ServiceUrls.authBasePath}api/v1/user/me`;
	static getStaffs = `${ServiceUrls.basePath}api/v1/staff`;
	static getStaffExport = `${ServiceUrls.basePath}api/v1/staff/members-export`;
	static emailStipendBankSheet = `${ServiceUrls.basePath}api/v1/staff/generate-bank-sheet-data`;
	static getStats = `${ServiceUrls.basePath}api/v1/stats`;
	static getCreateUpdateAssignments = `${ServiceUrls.basePath}api/v1/assignment`;
	static getAssignmentResponses = `${ServiceUrls.basePath}api/v1/assignment/response`;
	static downloadAllResponses = `${ServiceUrls.basePath}api/v1/assignment/response/all-assignment-responses`;
	static registerStaff = `${ServiceUrls.basePath}api/v1/staff`;
	static bulkRegisterFellows = `${ServiceUrls.basePath}api/v1/staff/bulk-fellow`;
	static createStaffSource = `${ServiceUrls.basePath}api/v1/staff/source`;
	static bulkdDispatchEngagementLetter = `${ServiceUrls.basePath}api/v1/staff/bulk-engagement-letter`;
	static terminateStaff = `${ServiceUrls.basePath}api/v1/staff/terminate-member`;
	static sendStaffTerminationCertificate = `${ServiceUrls.basePath}api/v1/staff/send-termination-certificate`;
	static addBankDetails = `${ServiceUrls.basePath}api/v1/staff/bank`;
	static getBankDetails = `${ServiceUrls.basePath}api/v1/staff/bank`;
	static updateStaff = `${ServiceUrls.basePath}api/v1/staff`;
	static resetStaffPassword = `${ServiceUrls.basePath}api/v1/staff/reset-password`;
	static requestOnBoardingForm = `${ServiceUrls.basePath}api/v1/staff/onboarding-form`;
	static updateSelf = `${ServiceUrls.basePath}api/v1/user/updateSelf`;
}

export default ServiceUrls;
